<template>
  <v-container id="subscribe" tag="section">
    <base-card color="rgba(203, 170, 92, 0.51)" class="">
      <v-container>
        <v-row>
          <v-col cols="12">
            <base-subheading>Contact</base-subheading>
          </v-col>
          <!-- FORM -->
          <!-- <v-col cols="12" md="6">
            <ContactForm />
          </v-col> -->
          <!-- FORM -->

          <v-col cols="12" md="6">
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4" dark> mdi-email-outline </v-icon>
                </v-list-item-avatar>

                <v-list-item-title class="d-none d-sm-flex"
                  >EMAIL</v-list-item-title
                >

                <v-list-item-subtitle>
                  <a href="mailto:reservation@jollytravelcancun.com"
                    >reservation@jollytravelcancun.com</a
                  >
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4" dark> mdi-map-marker </v-icon>
                </v-list-item-avatar>

                <v-list-item-title class="d-none d-sm-flex"
                  >ADDRESS</v-list-item-title
                >

                <v-list-item-subtitle>
                  Av. Xcaret, Mza 2, S.M 36 Edif. Cancun Lt 5-04 Desp 301 C.P.
                  77507. Cancun, Q.Roo
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4" dark> mdi-phone </v-icon>
                </v-list-item-avatar>

                <v-list-item-title class="d-none d-sm-flex"
                  >PHONE</v-list-item-title
                >

                <v-list-item-subtitle>
                  <a href="tel:+529982755023">+52 99-82-75-50-23</a>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: "HomeSubscribe",
  components: {
    ContactForm: () => import("@/components/base/Form"),
  },
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal !important;
}
</style>